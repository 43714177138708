import { useEffect, useState } from "react";
import { useFrame } from "react-frame-component";

export function useMediaQuery(query) {
  const [matches, setMatches] = useState(false);
  const { window } = useFrame();

  useEffect(() => {
    const media = window.matchMedia(query);

    if (media.matches !== matches) {
      setMatches(media.matches);
    }

    const listener = () => {
      setMatches(media.matches);
    };

    // Safari version 13.1 and earlier doesn't support addEventListener for
    // matchMedia
    media.addListener(listener);

    return () => media.removeListener(listener);
  }, [window, matches, setMatches, query]);

  return matches;
}

export function useLgScreenMediaQuery() {
  return useMediaQuery("(min-width: 992px)");
}

export function useMdScreenMediaQuery() {
  return useMediaQuery("(min-width: 768px)");
}

export function useSmScreenMediaQuery() {
  return useMediaQuery("(max-width: 575px)");
}

export function useTailwindSmScreenMediaQuery() {
  return useMediaQuery("(min-width: 640px)");
}

export function useTailwindMdScreenMediaQuery() {
  return useMediaQuery("(min-width: 768px)");
}

export function useTailwindLgScreenMediaQuery() {
  return useMediaQuery("(min-width: 1024px)");
}

export function useSmOrMdScreenMediaQuery() {
  return useMediaQuery("(max-width: 768px)");
}

export function useSmOrMdOrLgScreenMediaQuery() {
  return useMediaQuery("(max-width: 992px)");
}

export function useLargeScreenMediaQuery() {
  return useMediaQuery("(max-width: 1024px)");
}

export function useExtraLargeScreenMediaQuery() {
  return useMediaQuery("(min-width: 1280px)");
}

export function useExtraLargePlusScreenMediaQuery() {
  return useMediaQuery("(min-width: 1366px)");
}

export function useFourteenFortyScreenMediaQuery() {
  return useMediaQuery("(min-width: 1440px)");
}

export function useTwoExtraLargeScreenMediaQuery() {
  return useMediaQuery("(min-width: 1536px)");
}

export function useThreeExtraLargeScreenMediaQuery() {
  return useMediaQuery("(min-width: 1680px)");
}

/**
 * Test if the user has any available input mechanism that can hover over elements.
 * @returns {boolean}
 */
export function useAnyHoverMediaQuery() {
  return useMediaQuery("(any-hover:hover)");
}
